import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
// import Wallet from './pages/wallet';
// import HttpPage from './pages/Request';
// import Guidelines from './pages/guidelines';
// import Safety from './pages/safety';
// import ParentsGuide from './pages/parents_guide';
// import LegalNotice from './pages/legal-notice';

import { Suspense, lazy } from "react";

const Home = lazy(() => import("./pages/Home"));
const Terms = lazy(() => import("./pages/terms"));
const TermsApp = lazy(() => import("./pages/terms_app"));
const PrivacyPolicy = lazy(() => import("./pages/privacy_policy"));
const PrivacyPolicyApp = lazy(() => import("./pages/privacy_policy_app"));
const SharePage = lazy(() => import("./pages/share"));
const SharePageSandBox = lazy(() => import("./pages/share-sandbox"));
const RulesLeaderBoard = lazy(() => import("./pages/rules/leader-board"));
const RulesCommission = lazy(() => import("./pages/rules/commission"));
const WithdrawOkx = lazy(() => import("./pages/withdraw-okx"));
const RulesUser = lazy(() => import("./pages/rules/user"));
const WithdrawBingx = lazy(() => import("./pages/withdraw-bingx"));

function App() {
  return (
    <>
      <Router>
        <Suspense fallback>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/app/terms" element={<TermsApp />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/app/privacy_policy" element={<PrivacyPolicyApp />} />
            <Route path="/share" element={<SharePage />} />
            <Route path="/rules/leader-board" element={<RulesLeaderBoard />} />
            <Route path="/rules/commission" element={<RulesCommission />} />
            <Route path="/withdraw-okx" element={<WithdrawOkx />} />
            <Route path="/rules/user" element={<RulesUser />} />
            <Route path="/share-sandbox" element={<SharePageSandBox />} />


            <Route path="/withdraw-bingx" element={<WithdrawBingx />} />
            <Route path="/withdraw-bingx/:locales" element={<WithdrawBingx />} />

            {/* <Route path="/safety" element={<Safety />} />
            <Route path="/parents_guide" element={<ParentsGuide />} />
            <Route path="/legal-notice" element={<LegalNotice />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/http" element={<HttpPage />} /> */}
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Routes>
        </Suspense>
        <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
      </Router>
    </>
  );
}

export default App;
