import en from "./en.json";
import kr from "./kr.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { I18Enum } from "../enums";

// 国际化相关
const i18Resources = {
  en: {
    translation: en,
  },
  kr: {
    translation: kr,
  },
};

// const language = localStorage.getItem(LOCAL_STORAGE_I18);

i18n.use(initReactI18next).init({
  resources: i18Resources,
  lng: I18Enum.EN,
  fallbackLng: I18Enum.EN,
  interpolation: {
    escapeValue: false,
  },
});
